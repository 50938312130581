/* Extra small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

/* Standar Vertical Mobile */

.card-player {
  background: url(../src/js/assets/img/fondo.png);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Bebas-Bold";
  src: local("Bebas-Bold"),
    url(./fonts/Bebas/BebasNeueBold.otf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url(./fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url(./fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url(./fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url(./fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url(./fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "DINCondensed";
  src: local("DINCondensed-Bold"),
    url(./fonts/DINCondensed/DINCondensed-Bold.ttf) format("truetype");
}

* {
  padding: 0;
  margin: 0;
  outline: none;
}

hr {
  margin: 15px;
  width: 100%;
}

h3 {
  text-transform: uppercase;
}

body {
  font-family: "Open Sans", sans-serif;
  font-family: "Open Sans Condensed", sans-serif;
  font-family: "Montserrat", sans-serif;
  font-family: "Poppins";
  color: #fff;
}

#side-nav-bar .logo {
  width: 138px;
  max-height: 72px;
  margin-bottom: 15px;
}

/* .MuiOutlinedInput-root.Mui-focused,
.MuiOutlinedInput-notchedOutline
{
  border-color: #5adedc !important;
} */

.MuiOutlinedInput-root {
  background-color: #fff;
}

.MuiFormLabel-root.Mui-focused {
  color: #fff !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -16px) scale(0.75) !important;
}

#root {
  width: 100%;
  display: flex;
  background-color: #131319;
  height: 100vh;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.main {
  min-height: calc(100vh - 30px);
}

.center-col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.container .center-col > *,
.container .center-row > *,
.container-fluid .center-col > *,
.container-fluid .center-row > * {
  margin: 15px;
}

.container .center-row,
.container-fluid .center-row {
  display: flex;
  justify-content: center;
  flex-direction: initial;
}

.container-fluid .none-margin > * {
  margin: 0;
}

.container .cols.center-row,
.container-fluid .cols.center-row {
  display: inline-flex;
}

.col {
  display: flex;
  margin: 15px 0;
}

.col.align-items-center {
  align-items: center;
}

.col.space-between {
  justify-content: space-between;
}

.cols {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rows {
  display: flex;
  flex-direction: column;
}

.content-right {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.MuiIconButton-label {
  color: rgba(255, 255, 255, 0.54);
}

.Mui-checked .MuiIconButton-label,
.MuiRadio-colorPrimary.Mui-checked {
  color: #5adedc !important;
}

.child-color .MuiFormLabel-root {
  color: #fff !important;
}

.big-avatar {
  width: 100%;
}

.default-buttom {
  color: #fff;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #2259e4;
  border-radius: 1.9px;
  height: 43.7px;
  text-transform: uppercase;
  padding: 0 15px;
}

.default-buttom span {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 14.25px;
  font-weight: 700;
}

.cols .default-buttom {
  padding: 0;
}

.default-buttom > * {
  margin: 5px;
}

.default-buttom:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  opacity: 0.8;
}

.col.margin-none {
  margin: 0;
}

.container-fluid .cols,
.col.margin-none-y {
  margin-top: 0;
  margin-bottom: 0;
}

.col.margin-none-x {
  margin-left: 0;
  margin-right: 0;
}

.content-margin-y > * {
  margin-top: 15px;
  margin-bottom: 15px;
}

.content-margin-x > * {
  margin-left: 15px;
  margin-right: 15px;
}

.paginator-container {
  display: flex;
}

.paginator-item {
  width: 20px;
  height: 20px;
  margin: 0 4px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  font-family: "Montserrat-Regular";
}

.paginator-item:hover {
  transform: scale(1.3);
  font-weight: 900;
}

.paginator-item.active {
  font-family: "Montserrat-Bold";
  font-weight: 900;
  transition: all 0.3s;
}
.paginator-item .arrow {
  border: solid #1bddda;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.paginator-item .arrow.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.paginator-item .arrow.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.text-center {
  text-align: center;
}

.MuiPickersBasePicker-container .MuiButtonBase-root .MuiTypography-root {
  color: #000;
}

.MuiPickersDay-daySelected .MuiIconButton-label .MuiTypography-root {
  color: #fff !important;
}

.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar {
  background-color: #3a3c3e;
}

.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar .MuiButton-label * {
  color: #fff;
}

.MuiPickersBasePicker-container .MuiIconButton-label .MuiSvgIcon-root {
  color: #000;
}

.gpexe-table {
  margin: 15px 0;
}

.gpexe-table .unit {
  display: flex;
  flex-direction: column;
}

.gpexe-table .unit span {
  font-size: 12px;
  color: #ffffff78;
}

.gpexe-table th {
  padding: 0 0 15px;
}

/* Extra small devices (phones, 600px and down) xs */
@media only screen and (max-width: 576px) {
  div {
    flex-direction: column;
  }

  .col {
    width: calc(100% - 30px);
  }

  .main {
    margin: 0;
  }

  .cols.sm-2 > *,
  .cols.sm-3 > *,
  .cols.sm-4 > *,
  .cols.md-3 > *,
  .cols.lg-6 > * {
    width: calc(100% - 15px);
    display: flex;
    margin: 15px auto;
  }

  .cols.self-sm-2 {
    width: 50%;
  }
}

/* Small devices (landscape phones, 576px and up) sm */
@media (min-width: 576px) {
  .container {
    max-width: 1140px;
  }

  .cols.sm-2 > * {
    width: calc(50% - 30px);
    /* margin: 15px; */
  }

  .cols.sm-3 > * {
    width: calc((100% / 3) - 30px);
    /* margin: 15px; */
  }

  .cols.sm-4 > * {
    width: calc(25% - 30px);
    /* margin: 15px; */
  }

  .cols.sm-6 > * {
    width: calc((100% / 6) - 30px);
    /* margin: 15px; */
  }

  .cols.self-sm-12 {
    display: flex;
    width: calc(100% - 15px);
  }

  .select-players-gps .cols.sm-4 .MuiAvatar-root-container {
    cursor: pointer;
  }

  .select-players-gps .cols.sm-6 .MuiAvatar-root-container {
    cursor: pointer;
  }
}

/* Medium devices (tablets, 768px and up) md */
@media (max-width: 768px) {
  .burger {
    position: absolute;
    right: 35px;
  }
}

/* Medium devices (tablets, 768px and up) md */
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .cols.md-3 > * {
    width: calc((100% / 3) - 30px);
    /* margin: 15px; */
  }

  .cols.md-4 > * {
    width: calc(25% - 30px);
    /* margin: 15px; */
  }

  .cols.md-5 > * {
    width: calc(20% - 30px);
    /* margin: 15px; */
  }

  .cols.md-10 > * {
    width: calc(10% - 30px);
    /* margin: 15px; */
  }

  .select-players-gps .cols.md-6 .MuiAvatar-root-container {
    cursor: pointer;
  }

  .select-players-gps .cols.md-10 .MuiAvatar-root-container {
    cursor: pointer;
  }

  .cols.self-md-12 {
    display: flex;
    width: calc(100% - 15px);
  }

  .cols.self-md-9 {
    display: flex;
    width: calc(75% - 15px);
  }

  .cols.self-md-6 {
    display: flex;
    width: calc(50% - 15px);
  }

  .cols.self-md-3 {
    display: flex;
    width: calc(25% - 15px);
  }
}

/* Large devices (desktops, 992px and up)  lg */
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  .cols.lg-2 > * {
    width: calc(50% - 30px);
    /* margin: 15px; */
  }

  .cols.lg-4 > * {
    width: calc(25% - 30px);
    /* margin: 15px; */
  }

  .cols.lg-6 > * {
    width: calc((100% / 6) - 30px);
    /* margin: 15px; */
  }

  .cols.lg-8 > * {
    width: calc((100% / 8) - 30px);
    /* margin: 15px; */
  }

  .cols.lg-12 > * {
    width: calc((100% / 12) - 30px);
    /* margin: 15px; */
  }

  .cols.lg-14 > * {
    width: calc((100% / 14) - 30px);
    /* margin: 15px; */
  }

  .cols.self-lg-12 {
    display: flex;
    width: calc(100% - 15px);
  }

  .cols.self-lg-9 {
    display: flex;
    width: calc(75% - 15px);
  }

  .cols.self-lg-6 {
    display: flex;
    width: calc(50% - 15px);
  }

  .cols.self-lg-3 {
    display: flex;
    width: calc(25% - 15px);
  }

  .select-players-gps .cols.lg-8 .MuiAvatar-root-container {
    width: calc(100% / 8);
    cursor: pointer;
    margin: 15px 0;
    text-align: center;
  }

  .select-players-gps .cols.lg-8 .MuiAvatar-root-container span {
    font-size: 14.25px;
  }

  .select-players-gps .cols.lg-12 .MuiAvatar-root-container {
    cursor: pointer;
  }

  .select-players-gps .cols.lg-14 .MuiAvatar-root-container {
    cursor: pointer;
  }
}

/* Extra large devices (large desktops, 1200px and up) xl */
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.MuiDialog-paperFullWidth {
  background-color: #17191d !important;
}
.MuiTypography-displayBlock {
  font-family: "Bebas-Bold" !important;
}
