.table-actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.table-actions-dots {
  width: 35px;
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.table-actions-dots.active {
  border: 2px solid #6bffe6;
  border-radius: 4px;
}

.table-actions-dots .actions-dot {
  display: block;
  width: 5px;
  height: 5px;
  margin: 2px;
  border-radius: 50%;
  background-color: #6bffe6;
}
.table-actions-action {
  position: absolute;
  top: 130%;
  right: 28%;
  background-color: #fff;
  z-index: 9;
  border-radius: 7px;
  box-sizing: border-box;
  padding: 5px;
}

.table-actions-action li {
  list-style: none;
  padding: 0.6rem 2rem 0.6rem 0.5rem;
  cursor: pointer;
  white-space: nowrap;
  color: #0a0a0a;
  display: flex;
  align-items: center;
}
.table-actions-action li:first-child {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}
.table-actions-action li:last-child {
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}
.table-actions-action li:hover {
  background-color: #f4f4f4;
}
.table-actions-action li a {
  color: #0a0a0a;
  text-decoration: none;
  display: block;
  width: 100%;
}
.table-actions-action li img {
  margin-right: 8px;
  fill: #6bffe6;
  stroke: #6bffe6;
}
.modal-paper {
  top: 50%;
  left: 50%;
  color: #000;
  padding: 15px;
  position: absolute;
  background: #fff;
  text-align: center;
  transform: translate(-50%, -50%);
}

.modal-paper .avatar {
  padding: 15px;
}

.modal-paper .button-group {
  text-align: center;
}

.modal-paper .button-group button {
  margin: 15px;
  padding: 15px;
  cursor: pointer;
}