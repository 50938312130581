.select-box--box {
  position: relative;
  box-sizing: border-box;
  z-index: 1000;
}
.select-box--box.disable {
  opacity: 0.7;
  pointer-events: none;
}
.select-box--container {
  height: 40px;
  width: 100%;
  margin: 0;
  position: relative;
}
.select-box--selected-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 1.75rem 0 0.75rem;
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-transform: capitalize;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 2px #fff;
  font-size: 15px;
  line-height: 2.3;
  color: #000;
  background-color: #fff;
  font-family: "Montserrat-Medium", sans-serif;
}
.select-box--selected-item.before {
  padding: 0 1.5rem;
}

.select-box--items {
  margin-top: -2px;
  background-color: white;
  color: #000;
  max-height: 47vh;
  overflow: auto;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  z-index: 1000;
}

.select-box--items div {
  border: 1px solid #ddd;
  border-top: none;
  padding: 10px 6px;
  position: relative;
  text-transform: capitalize;
  font-size: 15px;
  font-family: "Montserrat-Medium", sans-serif;
  z-index: 1000;
}

.select-box--items div:hover {
  cursor: pointer;
  background-color: rgb(235, 236, 240);
}

.select-box--items div.selected::after {
  content: "";
  position: absolute;
  right: 9px;
  top: 30%;
  width: 4px;
  height: 8px;
  border: solid rgb(113, 115, 134);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.select-box--arrow {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.select-box--arrow-down {
  position: absolute;
  top: 45%;
  right: 15px;
  width: 6px;
  height: 6px;
  border: solid rgb(113, 115, 134);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg) translateY(-50%);
  -ms-transform: rotate(45deg) translateY(-50%);
  transform: rotate(45deg) translateY(-50%);
  transition: 0.2s;
}

.select-box--arrow-up {
  position: absolute;
  top: 35%;
  right: 10px;
  width: 6px;
  height: 6px;
  border: solid rgb(113, 115, 134);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(-135deg) translateY(-50%);
  -ms-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
  transition: 0.2s;
}

.inputSearchClub {
  font-family: "Montserrat-Medium";
  font-size: 15;
  position: absolute;
  top: 50%;
  z-index: 100;
  width: 140px;
  border: none;
  transform: translateY(-50%);
}

.inputSearchClub::placeholder {
  color: black;
}
