/* Extra small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

/* Standar Vertical Mobile */

.card-player {
  background: url(/static/media/fondo.fa329200.png);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(/static/media/Poppins-Regular.41e8dead.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url(/static/media/Poppins-Bold.c23534ac.ttf) format("truetype");
}

@font-face {
  font-family: "Bebas-Bold";
  src: local("Bebas-Bold"),
    url(/static/media/BebasNeueBold.524d720f.otf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url(/static/media/Montserrat-Regular.ee653992.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url(/static/media/Montserrat-Medium.c8b6e083.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url(/static/media/Montserrat-Medium.c8b6e083.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url(/static/media/Montserrat-SemiBold.c641dbee.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url(/static/media/Montserrat-Bold.ade91f47.ttf) format("truetype");
}

@font-face {
  font-family: "DINCondensed";
  src: local("DINCondensed-Bold"),
    url(/static/media/DINCondensed-Bold.904d4d9d.ttf) format("truetype");
}

* {
  padding: 0;
  margin: 0;
  outline: none;
}

hr {
  margin: 15px;
  width: 100%;
}

h3 {
  text-transform: uppercase;
}

body {
  font-family: "Open Sans", sans-serif;
  font-family: "Open Sans Condensed", sans-serif;
  font-family: "Montserrat", sans-serif;
  font-family: "Poppins";
  color: #fff;
}

#side-nav-bar .logo {
  width: 138px;
  max-height: 72px;
  margin-bottom: 15px;
}

/* .MuiOutlinedInput-root.Mui-focused,
.MuiOutlinedInput-notchedOutline
{
  border-color: #5adedc !important;
} */

.MuiOutlinedInput-root {
  background-color: #fff;
}

.MuiFormLabel-root.Mui-focused {
  color: #fff !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  -webkit-transform: translate(14px, -16px) scale(0.75) !important;
          transform: translate(14px, -16px) scale(0.75) !important;
}

#root {
  width: 100%;
  display: flex;
  background-color: #131319;
  height: 100vh;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.main {
  min-height: calc(100vh - 30px);
}

.center-col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.container .center-col > *,
.container .center-row > *,
.container-fluid .center-col > *,
.container-fluid .center-row > * {
  margin: 15px;
}

.container .center-row,
.container-fluid .center-row {
  display: flex;
  justify-content: center;
  flex-direction: initial;
}

.container-fluid .none-margin > * {
  margin: 0;
}

.container .cols.center-row,
.container-fluid .cols.center-row {
  display: inline-flex;
}

.col {
  display: flex;
  margin: 15px 0;
}

.col.align-items-center {
  align-items: center;
}

.col.space-between {
  justify-content: space-between;
}

.cols {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rows {
  display: flex;
  flex-direction: column;
}

.content-right {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.MuiIconButton-label {
  color: rgba(255, 255, 255, 0.54);
}

.Mui-checked .MuiIconButton-label,
.MuiRadio-colorPrimary.Mui-checked {
  color: #5adedc !important;
}

.child-color .MuiFormLabel-root {
  color: #fff !important;
}

.big-avatar {
  width: 100%;
}

.default-buttom {
  color: #fff;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #2259e4;
  border-radius: 1.9px;
  height: 43.7px;
  text-transform: uppercase;
  padding: 0 15px;
}

.default-buttom span {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 14.25px;
  font-weight: 700;
}

.cols .default-buttom {
  padding: 0;
}

.default-buttom > * {
  margin: 5px;
}

.default-buttom:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  opacity: 0.8;
}

.col.margin-none {
  margin: 0;
}

.container-fluid .cols,
.col.margin-none-y {
  margin-top: 0;
  margin-bottom: 0;
}

.col.margin-none-x {
  margin-left: 0;
  margin-right: 0;
}

.content-margin-y > * {
  margin-top: 15px;
  margin-bottom: 15px;
}

.content-margin-x > * {
  margin-left: 15px;
  margin-right: 15px;
}

.paginator-container {
  display: flex;
}

.paginator-item {
  width: 20px;
  height: 20px;
  margin: 0 4px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-family: "Montserrat-Regular";
}

.paginator-item:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  font-weight: 900;
}

.paginator-item.active {
  font-family: "Montserrat-Bold";
  font-weight: 900;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.paginator-item .arrow {
  border: solid #1bddda;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.paginator-item .arrow.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.paginator-item .arrow.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.text-center {
  text-align: center;
}

.MuiPickersBasePicker-container .MuiButtonBase-root .MuiTypography-root {
  color: #000;
}

.MuiPickersDay-daySelected .MuiIconButton-label .MuiTypography-root {
  color: #fff !important;
}

.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar {
  background-color: #3a3c3e;
}

.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar .MuiButton-label * {
  color: #fff;
}

.MuiPickersBasePicker-container .MuiIconButton-label .MuiSvgIcon-root {
  color: #000;
}

.gpexe-table {
  margin: 15px 0;
}

.gpexe-table .unit {
  display: flex;
  flex-direction: column;
}

.gpexe-table .unit span {
  font-size: 12px;
  color: #ffffff78;
}

.gpexe-table th {
  padding: 0 0 15px;
}

/* Extra small devices (phones, 600px and down) xs */
@media only screen and (max-width: 576px) {
  div {
    flex-direction: column;
  }

  .col {
    width: calc(100% - 30px);
  }

  .main {
    margin: 0;
  }

  .cols.sm-2 > *,
  .cols.sm-3 > *,
  .cols.sm-4 > *,
  .cols.md-3 > *,
  .cols.lg-6 > * {
    width: calc(100% - 15px);
    display: flex;
    margin: 15px auto;
  }

  .cols.self-sm-2 {
    width: 50%;
  }
}

/* Small devices (landscape phones, 576px and up) sm */
@media (min-width: 576px) {
  .container {
    max-width: 1140px;
  }

  .cols.sm-2 > * {
    width: calc(50% - 30px);
    /* margin: 15px; */
  }

  .cols.sm-3 > * {
    width: calc((100% / 3) - 30px);
    /* margin: 15px; */
  }

  .cols.sm-4 > * {
    width: calc(25% - 30px);
    /* margin: 15px; */
  }

  .cols.sm-6 > * {
    width: calc((100% / 6) - 30px);
    /* margin: 15px; */
  }

  .cols.self-sm-12 {
    display: flex;
    width: calc(100% - 15px);
  }

  .select-players-gps .cols.sm-4 .MuiAvatar-root-container {
    cursor: pointer;
  }

  .select-players-gps .cols.sm-6 .MuiAvatar-root-container {
    cursor: pointer;
  }
}

/* Medium devices (tablets, 768px and up) md */
@media (max-width: 768px) {
  .burger {
    position: absolute;
    right: 35px;
  }
}

/* Medium devices (tablets, 768px and up) md */
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .cols.md-3 > * {
    width: calc((100% / 3) - 30px);
    /* margin: 15px; */
  }

  .cols.md-4 > * {
    width: calc(25% - 30px);
    /* margin: 15px; */
  }

  .cols.md-5 > * {
    width: calc(20% - 30px);
    /* margin: 15px; */
  }

  .cols.md-10 > * {
    width: calc(10% - 30px);
    /* margin: 15px; */
  }

  .select-players-gps .cols.md-6 .MuiAvatar-root-container {
    cursor: pointer;
  }

  .select-players-gps .cols.md-10 .MuiAvatar-root-container {
    cursor: pointer;
  }

  .cols.self-md-12 {
    display: flex;
    width: calc(100% - 15px);
  }

  .cols.self-md-9 {
    display: flex;
    width: calc(75% - 15px);
  }

  .cols.self-md-6 {
    display: flex;
    width: calc(50% - 15px);
  }

  .cols.self-md-3 {
    display: flex;
    width: calc(25% - 15px);
  }
}

/* Large devices (desktops, 992px and up)  lg */
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  .cols.lg-2 > * {
    width: calc(50% - 30px);
    /* margin: 15px; */
  }

  .cols.lg-4 > * {
    width: calc(25% - 30px);
    /* margin: 15px; */
  }

  .cols.lg-6 > * {
    width: calc((100% / 6) - 30px);
    /* margin: 15px; */
  }

  .cols.lg-8 > * {
    width: calc((100% / 8) - 30px);
    /* margin: 15px; */
  }

  .cols.lg-12 > * {
    width: calc((100% / 12) - 30px);
    /* margin: 15px; */
  }

  .cols.lg-14 > * {
    width: calc((100% / 14) - 30px);
    /* margin: 15px; */
  }

  .cols.self-lg-12 {
    display: flex;
    width: calc(100% - 15px);
  }

  .cols.self-lg-9 {
    display: flex;
    width: calc(75% - 15px);
  }

  .cols.self-lg-6 {
    display: flex;
    width: calc(50% - 15px);
  }

  .cols.self-lg-3 {
    display: flex;
    width: calc(25% - 15px);
  }

  .select-players-gps .cols.lg-8 .MuiAvatar-root-container {
    width: calc(100% / 8);
    cursor: pointer;
    margin: 15px 0;
    text-align: center;
  }

  .select-players-gps .cols.lg-8 .MuiAvatar-root-container span {
    font-size: 14.25px;
  }

  .select-players-gps .cols.lg-12 .MuiAvatar-root-container {
    cursor: pointer;
  }

  .select-players-gps .cols.lg-14 .MuiAvatar-root-container {
    cursor: pointer;
  }
}

/* Extra large devices (large desktops, 1200px and up) xl */
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.MuiDialog-paperFullWidth {
  background-color: #17191d !important;
}
.MuiTypography-displayBlock {
  font-family: "Bebas-Bold" !important;
}

.table-actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.table-actions-dots {
  width: 35px;
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.table-actions-dots.active {
  border: 2px solid #6bffe6;
  border-radius: 4px;
}

.table-actions-dots .actions-dot {
  display: block;
  width: 5px;
  height: 5px;
  margin: 2px;
  border-radius: 50%;
  background-color: #6bffe6;
}
.table-actions-action {
  position: absolute;
  top: 130%;
  right: 28%;
  background-color: #fff;
  z-index: 9;
  border-radius: 7px;
  box-sizing: border-box;
  padding: 5px;
}

.table-actions-action li {
  list-style: none;
  padding: 0.6rem 2rem 0.6rem 0.5rem;
  cursor: pointer;
  white-space: nowrap;
  color: #0a0a0a;
  display: flex;
  align-items: center;
}
.table-actions-action li:first-child {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}
.table-actions-action li:last-child {
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}
.table-actions-action li:hover {
  background-color: #f4f4f4;
}
.table-actions-action li a {
  color: #0a0a0a;
  text-decoration: none;
  display: block;
  width: 100%;
}
.table-actions-action li img {
  margin-right: 8px;
  fill: #6bffe6;
  stroke: #6bffe6;
}
.modal-paper {
  top: 50%;
  left: 50%;
  color: #000;
  padding: 15px;
  position: absolute;
  background: #fff;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modal-paper .avatar {
  padding: 15px;
}

.modal-paper .button-group {
  text-align: center;
}

.modal-paper .button-group button {
  margin: 15px;
  padding: 15px;
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.select-box--box {
  position: relative;
  box-sizing: border-box;
  z-index: 1000;
}
.select-box--box.disable {
  opacity: 0.7;
  pointer-events: none;
}
.select-box--container {
  height: 40px;
  width: 100%;
  margin: 0;
  position: relative;
}
.select-box--selected-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 1.75rem 0 0.75rem;
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-transform: capitalize;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 2px #fff;
  font-size: 15px;
  line-height: 2.3;
  color: #000;
  background-color: #fff;
  font-family: "Montserrat-Medium", sans-serif;
}
.select-box--selected-item.before {
  padding: 0 1.5rem;
}

.select-box--items {
  margin-top: -2px;
  background-color: white;
  color: #000;
  max-height: 47vh;
  overflow: auto;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  z-index: 1000;
}

.select-box--items div {
  border: 1px solid #ddd;
  border-top: none;
  padding: 10px 6px;
  position: relative;
  text-transform: capitalize;
  font-size: 15px;
  font-family: "Montserrat-Medium", sans-serif;
  z-index: 1000;
}

.select-box--items div:hover {
  cursor: pointer;
  background-color: rgb(235, 236, 240);
}

.select-box--items div.selected::after {
  content: "";
  position: absolute;
  right: 9px;
  top: 30%;
  width: 4px;
  height: 8px;
  border: solid rgb(113, 115, 134);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.select-box--arrow {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}

.select-box--arrow-down {
  position: absolute;
  top: 45%;
  right: 15px;
  width: 6px;
  height: 6px;
  border: solid rgb(113, 115, 134);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg) translateY(-50%);
  transform: rotate(45deg) translateY(-50%);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.select-box--arrow-up {
  position: absolute;
  top: 35%;
  right: 10px;
  width: 6px;
  height: 6px;
  border: solid rgb(113, 115, 134);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.inputSearchClub {
  font-family: "Montserrat-Medium";
  font-size: 15;
  position: absolute;
  top: 50%;
  z-index: 100;
  width: 140px;
  border: none;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.inputSearchClub::-webkit-input-placeholder {
  color: black;
}

.inputSearchClub::-moz-placeholder {
  color: black;
}

.inputSearchClub::-ms-input-placeholder {
  color: black;
}

.inputSearchClub::placeholder {
  color: black;
}

.table {
  border-collapse: collapse;
  border: none;
  width: 100%;
  font-family: "Montserrat-Semibold", sans-serif;
}

.table thead tr td {
  padding: 0.95rem 0;
  font-size: 1em;
  color: #6bffe6;
  border: none;
  text-transform: uppercase;
}
.table thead tr td:first-child,
.table thead tr td:last-child {
  width: 6rem;
}

.table-img-container {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff;
  margin: auto;
  padding: 2.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-img-container img {
  width: 30px;
  height: 30px;
}
.table-name-container {
  display: flex;
  align-items: center;
}

.table tbody tr td {
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  padding: 0.7rem 0;
  text-align: start;
}

.table tbody .row-items:nth-child(odd) {
  background-color: rgba(21, 23, 25, 0.7);
}

.table tfoot tr td:first-child {
  border-bottom-left-radius: 8px;
}
.table tfoot tr td:last-child {
  border-bottom-right-radius: 8px;
}

.headers-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.tableSaveContainer {
  padding: 5px 15px;
  margin-left: 25px;
  font-size: 15px;
  outline: none;
  font-family: "Montserrat-Medium";
  background-color: #014483;
  border: 0px solid;
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
