.table {
  border-collapse: collapse;
  border: none;
  width: 100%;
  font-family: "Montserrat-Semibold", sans-serif;
}

.table thead tr td {
  padding: 0.95rem 0;
  font-size: 1em;
  color: #6bffe6;
  border: none;
  text-transform: uppercase;
}
.table thead tr td:first-child,
.table thead tr td:last-child {
  width: 6rem;
}

.table-img-container {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff;
  margin: auto;
  padding: 2.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-img-container img {
  width: 30px;
  height: 30px;
}
.table-name-container {
  display: flex;
  align-items: center;
}

.table tbody tr td {
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  padding: 0.7rem 0;
  text-align: start;
}

.table tbody .row-items:nth-child(odd) {
  background-color: rgba(21, 23, 25, 0.7);
}

.table tfoot tr td:first-child {
  border-bottom-left-radius: 8px;
}
.table tfoot tr td:last-child {
  border-bottom-right-radius: 8px;
}

.headers-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.tableSaveContainer {
  padding: 5px 15px;
  margin-left: 25px;
  font-size: 15px;
  outline: none;
  font-family: "Montserrat-Medium";
  background-color: #014483;
  border: 0px solid;
  color: white;
}
